import { defineStore } from 'pinia';
import { type Product } from '~/interfaces/Product';

export const useProductStore = defineStore('product', () => {
  const { $http } = useNuxtApp();
  const products = ref<Product[]>([]);
  const searchProducts = ref<Product[]>([]);
  const selectedProduct = ref<Product>();
  const isProductLoading = ref<boolean>(false);
  const isProductsLoading = ref<boolean>(false);
  const isSearchLoading = ref<boolean>(false);

  function setSingleProduct(data: Product) {
    selectedProduct.value = data;
  }

  function setProducts(data: Product[]) {
    products.value = data;
  }

  function setSearchProducts(data: Product[]) {
    searchProducts.value = data;
  }

  async function fetchProducts(locale = 'en') {
    isProductsLoading.value = true;
    try {
      const response = await $http.get('products', {
        headers: {
          'Accept-Language': locale,
        },
      });
      const data: Product[] = response.data.data;
      products.value = data;
    } catch (error) {
      console.error(error);
    } finally {
      isProductsLoading.value = false;
    }
  }

  async function fetchSearchProducts(search: string) {
    isSearchLoading.value = true;
    try {
      const response = await $http.get(`products/search?q=${search}`);
      const data: Product[] = response.data.data;
      searchProducts.value = data;
    } catch (error) {
      console.error(error);
    } finally {
      isSearchLoading.value = false;
    }
  }

  return {
    products,
    searchProducts,
    selectedProduct,
    isProductLoading,
    isProductsLoading,
    isSearchLoading,
    setSingleProduct,
    setProducts,
    setSearchProducts,
    fetchProducts,
    fetchSearchProducts,
  };
});
